<template>
    <main>
        <div class="titlebar titlebar-sm scheme-dark bg-dark-blue">

            <div class="titlebar-inner py-5">
                <div class="container titlebar-container">
                    <div class="row titlebar-container">

                        <div class="titlebar-col col-md-6">
                            <h1 class="font-size-46 mb-1 text-white">Our Services</h1>
                            <p class="font-size-14 text-fade-white-08">All of our specialized services are designed to replace chaos with order</p>
                        </div><!-- /.titlebar-col -->

                        <div class="titlebar-col col-md-6 text-md-right">
                            <ol class="breadcrumb reset-ul inline-nav">
                                <li><a href="/"><span>Home</span></a></li>
                                <li><span>Our services</span></li>
                            </ol>
                        </div><!-- /.titlebar-col -->

                    </div><!-- /.titlebar-row -->
                </div><!-- /.titlebar-container -->
            </div><!-- /.titlebar-inner -->

        </div>
        <section class="vc_row pt-80 pb-70  bg-athens-gray">
            <div class="container">
                <div class="row">

                    <div class="lqd-column col-md-6 col-md-offset-3 text-center mb-30">

                        <h4 class="mt-0 mb-3">1/2. Software Development</h4>
                        <h6 class="font-size-16 lh-15 text-primary">
                            As a software engineering company, we give our clients
                            clear-cut services to aid their business processes.
                        </h6>

                    </div><!-- /.col-md-6 col-md-offset-3 -->

                    <div class="lqd-column col-md-12">

                        <div class="tabs tabs-nav-side tabs-nav-side-alt tabs-nav-side-block flex-lg-row-reverse">

                            <ul class="nav tabs-nav" role="tablist">
                                <li role="presentation" class="active">
                                    <a href="#ld-tab-pane-48" aria-controls="ld-tab-pane-48" role="tab" data-toggle="tab">
                                        Web Design/Development
                                        <span>We capture the best browser experiences and create websites from any prototype.</span>
                                    </a>
                                </li>
                                <li role="presentation">
                                    <a href="#ld-tab-pane-49" aria-controls="ld-tab-pane-49" role="tab" data-toggle="tab">
                                        Mobile Development
                                        <span>We fuse together fantastic mobile applications using Flutter.</span>
                                    </a>
                                </li>
                                <li role="presentation">
                                    <a href="#ld-tab-pane-50" aria-controls="ld-tab-pane-50" role="tab" data-toggle="tab">
                                        API Development
                                        <span>We communicate business logic via several endpoints/servers.</span>
                                    </a>
                                </li>
                            </ul>

                            <div class="tabs-content">

                                <div role="tabpanel" class="tabs-pane fade active in" id="ld-tab-pane-48">

                                    <div class="fancy-box fancy-box-case-study scheme-light" data-hover3d="true" style="height: 490px;">
                                        <div class="fancy-box-contents border-radius-5" data-stacking-factor="0.5">
                                            <div class="cb-img-container border-radius-5">
                                                <figure class="fancy-box-image border-radius-5 loaded" data-responsive-bg="true">
                                                    <img class="invisible" src="/assets/img/actual/w1.jpg" alt="Visiontechers Services">
                                                </figure>
                                            </div><!-- /.cb-img-container -->
                                            <div class="fancy-box-header">
                                                <h3 class="mb-2 font-size-30">Web Development</h3>
                                                <p class="text-fade-white-06">
                                                    We develop web applications ranging from personal portfolios to E-portals to Business Applications to
                                                    E-commerce web applications
                                                </p>
                                            </div><!-- /.fancy-box-header -->
                                        </div><!-- /.fancy-box-contents -->
                                    </div><!-- /.fancy-box -->

                                </div><!-- /.tab-pane -->

                                <div role="tabpanel" class="tabs-pane fade" id="ld-tab-pane-49">

                                    <div class="fancy-box fancy-box-case-study scheme-light" data-hover3d="true" style="height: 490px;">
                                        <div class="fancy-box-contents border-radius-5" data-stacking-factor="0.5">
                                            <div class="cb-img-container border-radius-5">
                                                <figure class="fancy-box-image border-radius-5 loaded" data-responsive-bg="true">
                                                    <img class="invisible" src="/assets/img/actual/w2.jpg" alt="Visiontechers Services">
                                                </figure>
                                            </div><!-- /.cb-img-container -->
                                            <div class="fancy-box-header">
                                                <h3 class="mb-2 font-size-30">Mobile Development</h3>
                                                <p class="text-fade-white-06">
                                                    We provide development of mobile applications supported by the main mobile Operating System ( IOS & Andriod )
                                                </p>
                                            </div><!-- /.fancy-box-header -->
                                        </div><!-- /.fancy-box-contents -->
                                    </div><!-- /.fancy-box -->

                                </div><!-- /.tab-pane -->

                                <div role="tabpanel" class="tabs-pane fade" id="ld-tab-pane-50">

                                    <div class="fancy-box fancy-box-case-study scheme-light" data-hover3d="true" style="height: 490px;">
                                        <div class="fancy-box-contents border-radius-5" data-stacking-factor="0.5">
                                            <div class="cb-img-container border-radius-5">
                                                <figure class="fancy-box-image border-radius-5 loaded" data-responsive-bg="true">
                                                    <img class="invisible" src="/assets/img/actual/w3.jpg" alt="Visiontechers Services">
                                                </figure>
                                            </div><!-- /.cb-img-container -->
                                            <div class="fancy-box-header">
                                                <h3 class="mb-2 font-size-30">API Development</h3>
                                                <p class="text-fade-white-06">
                                                    Strictly communicating business logic via several endpoints/servers.
                                                </p>
                                            </div><!-- /.fancy-box-header -->
                                        </div><!-- /.fancy-box-contents -->
                                    </div><!-- /.fancy-box -->

                                </div><!-- /.tab-pane -->

                            </div><!-- /.tabs-content -->

                        </div><!-- /.tabs -->

                    </div><!-- /.col-md-12 -->

                </div><!-- /.row -->
            </div><!-- /.container -->
        </section>

        <section class="vc_row pt-80 pb-70 mb-65 bg-athens-gray">
            <div class="container">
                <div class="row">

                    <div class="lqd-column col-md-6 col-md-offset-3 text-center mb-30">

                        <h4 class="mt-0 mb-3">2/2. Design Services</h4>
                        <h6 class="font-size-16 lh-15"> Our design lab has been fully developed to engage clients beyond the conventional design and development</h6>

                    </div><!-- /.col-md-6 col-md-offset-3 -->

                    <div class="lqd-column col-md-12">

                        <div class="tabs tabs-nav-side tabs-nav-side-alt tabs-nav-side-block flex-lg-row-reverse">

                            <ul class="nav tabs-nav" role="tablist">
                                <li role="presentation" class="active">
                                    <a href="#ld-tab-pane-148" aria-controls="ld-tab-pane-48" role="tab" data-toggle="tab">
                                        Branding
                                        <span>Your brand name or symbol identifies your services as ...</span>
                                    </a>
                                </li>
                                <li role="presentation">
                                    <a href="#ld-tab-pane-149" aria-controls="ld-tab-pane-49" role="tab" data-toggle="tab">
                                        UI / UX Development
                                        <span> Our designers are sure to give you that interactive design for ...</span>
                                    </a>
                                </li>
                                <li role="presentation">
                                    <a href="#ld-tab-pane-150" aria-controls="ld-tab-pane-50" role="tab" data-toggle="tab">
                                        Print Designs
                                        <span>Cards, flyers and banners are a good means of advertisement, we ...</span>
                                    </a>
                                </li>
                            </ul>

                            <div class="tabs-content">

                                <div role="tabpanel" class="tabs-pane fade active in" id="ld-tab-pane-148">

                                    <div class="fancy-box fancy-box-case-study scheme-light" data-hover3d="true" style="height: 490px;">
                                        <div class="fancy-box-contents border-radius-5" data-stacking-factor="0.5">
                                            <div class="cb-img-container border-radius-5">
                                                <figure class="fancy-box-image border-radius-5 loaded" data-responsive-bg="true">
                                                    <img class="invisible" src="/assets/img/design/brand.jfif" alt="Simple">
                                                </figure>
                                            </div><!-- /.cb-img-container -->
                                            <div class="fancy-box-header">
                                                <h3 class="mb-2 font-size-30">Branding</h3>
                                                <p class="w-60">
                                                    Your brand name or symbol identifies your services as distinct from other sellers. Let us help you design a brand name that will stand you out from others.
                                                </p>
                                            </div><!-- /.fancy-box-header -->
                                        </div><!-- /.fancy-box-contents -->
                                    </div><!-- /.fancy-box -->

                                </div><!-- /.tab-pane -->

                                <div role="tabpanel" class="tabs-pane fade" id="ld-tab-pane-149">

                                    <div class="fancy-box fancy-box-case-study scheme-light" data-hover3d="true" style="height: 490px;">
                                        <div class="fancy-box-contents border-radius-5" data-stacking-factor="0.5">
                                            <div class="cb-img-container border-radius-5">
                                                <figure class="fancy-box-image border-radius-5 loaded" data-responsive-bg="true">
                                                    <img class="invisible" src="/assets/img/design/ui-ux.png" alt="Simple">
                                                </figure>
                                            </div><!-- /.cb-img-container -->
                                            <div class="fancy-box-header">
                                                <h3 class="mb-2 font-size-30"> UI / UX Development</h3>
                                                <p class="w-60">
                                                    Our designers are sure to give you that interactive design for your web application for a positive user experience
                                                </p>
                                            </div><!-- /.fancy-box-header -->
                                        </div><!-- /.fancy-box-contents -->
                                    </div><!-- /.fancy-box -->

                                </div><!-- /.tab-pane -->

                                <div role="tabpanel" class="tabs-pane fade" id="ld-tab-pane-150">

                                    <div class="fancy-box fancy-box-case-study scheme-light" data-hover3d="true" style="height: 490px;">
                                        <div class="fancy-box-contents border-radius-5" data-stacking-factor="0.5">
                                            <div class="cb-img-container border-radius-5">
                                                <figure class="fancy-box-image border-radius-5 loaded" data-responsive-bg="true">
                                                    <img class="invisible" src="/assets/img/design/flyer.jfif" alt="Simple">
                                                </figure>
                                            </div><!-- /.cb-img-container -->
                                            <div class="fancy-box-header">
                                                <h3 class="mb-2 font-size-30">Print Designs</h3>
                                                <p class="w-60">
                                                    Cards, flyers and banners are a good means of advertisement, we provide world-class print designs.
                                                </p>
                                            </div><!-- /.fancy-box-header -->
                                        </div><!-- /.fancy-box-contents -->
                                    </div><!-- /.fancy-box -->

                                </div><!-- /.tab-pane -->

                            </div><!-- /.tabs-content -->

                        </div><!-- /.tabs -->

                    </div><!-- /.col-md-12 -->

                </div><!-- /.row -->
            </div><!-- /.container -->
        </section>

        <section class="vc_row pt-65 pb-50" id="how-we-work">
            <div class="container">
                <div class="row d-flex flex-wrap align-items-center">

                    <div class="lqd-column col-md-6 col-md-offset-3 text-center mb-80">

                        <h4 class="mt-0 mb-3">Other services</h4>
                        <h6 class="font-size-16 opacity-05">yes, we do more.</h6>

                    </div><!-- /.col-md-6 col-md-offset-3 -->
                </div><!-- /.row -->
            </div><!-- /.container -->
            <div class="container">
                <div class="row">
                    <div class="lqd-column col-md-4 col-sm-6 mb-40">

                        <div class="iconbox iconbox-xl iconbox-icon-image iconbox-shadow border-radius-5 px-5 pt-40 ">
                            <div class="iconbox-icon-wrap">
                            <span class="iconbox-icon-container text-black">
                                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                        <rect x="0" y="0" width="24" height="24"/>
                                        <path d="M7,2 L17,2 C18.1045695,2 19,2.8954305 19,4 L19,20 C19,21.1045695 18.1045695,22 17,22 L7,22 C5.8954305,22 5,21.1045695 5,20 L5,4 C5,2.8954305 5.8954305,2 7,2 Z M12,19 C9.790861,19 8,17.209139 8,15 C8,12.790861 9.790861,11 12,11 C14.209139,11 16,12.790861 16,15 C16,17.209139 14.209139,19 12,19 Z M12,7 C11.1715729,7 10.5,6.32842712 10.5,5.5 C10.5,4.67157288 11.1715729,4 12,4 C12.8284271,4 13.5,4.67157288 13.5,5.5 C13.5,6.32842712 12.8284271,7 12,7 Z" fill="#4d148c"/>
                                        <path d="M12,17 C13.1045695,17 14,16.1045695 14,15 C14,13.8954305 13.1045695,13 12,13 C10.8954305,13 10,13.8954305 10,15 C10,16.1045695 10.8954305,17 12,17 Z" fill="#4d148c" opacity="0.3"/>
                                    </g>
                                </svg>
                            </span>
                            </div><!-- /.iconbox-icon-wrap -->
                            <div class="contents">
                                <h3>Sound and Public address system supply</h3>
                            </div><!-- /.contants -->
                        </div><!-- /.iconbox -->

                    </div>
                    <div class="lqd-column col-md-4 col-sm-6 mb-40">

                        <div class="iconbox iconbox-xl iconbox-icon-image iconbox-shadow border-radius-5 px-5 pt-40 ">
                            <div class="iconbox-icon-wrap">
                            <span class="iconbox-icon-container text-black">
                                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                        <rect x="0" y="0" width="24" height="24"/>
                                        <path d="M5,7 L19,7 C20.1045695,7 21,7.8954305 21,9 L21,17 C21,18.1045695 20.1045695,19 19,19 L5,19 C3.8954305,19 3,18.1045695 3,17 L3,9 C3,7.8954305 3.8954305,7 5,7 Z M12,17 C14.209139,17 16,15.209139 16,13 C16,10.790861 14.209139,9 12,9 C9.790861,9 8,10.790861 8,13 C8,15.209139 9.790861,17 12,17 Z" fill="#4d148c"/>
                                        <rect fill="#4d148c" opacity="0.3" x="9" y="4" width="6" height="2" rx="1"/>
                                        <circle fill="#4d148c" opacity="0.3" cx="12" cy="13" r="2"/>
                                    </g>
                                </svg>
                            </span>
                            </div><!-- /.iconbox-icon-wrap -->
                            <div class="contents">
                                <h3>Photography and Videography</h3>
                            </div><!-- /.contants -->
                        </div><!-- /.iconbox -->
                    </div>
                    <div class="lqd-column col-md-4 col-sm-6 mb-40">

                        <div class="iconbox iconbox-xl iconbox-icon-image iconbox-shadow border-radius-5 px-5 pt-40 ">
                            <div class="iconbox-icon-wrap">
                            <span class="iconbox-icon-container text-black">
                               <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M3 7C3 4.79086 4.79086 3 7 3H17C19.2091 3 21 4.79086 21 7V17C21 19.2091 19.2091 21 17 21H7C4.79086 21 3 19.2091 3 17V7Z" fill="#4d148c" fill-opacity="0.25"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M17.5 7.5C18.0523 7.5 18.5 7.05228 18.5 6.5C18.5 5.94772 18.0523 5.5 17.5 5.5C16.9477 5.5 16.5 5.94772 16.5 6.5C16.5 7.05228 16.9477 7.5 17.5 7.5ZM10 12C10 10.8954 10.8954 10 12 10C13.1046 10 14 10.8954 14 12C14 13.1046 13.1046 14 12 14C10.8954 14 10 13.1046 10 12ZM12 8C9.79086 8 8 9.79086 8 12C8 14.2091 9.79086 16 12 16C14.2091 16 16 14.2091 16 12C16 9.79086 14.2091 8 12 8Z" fill="#4d148c"/>
                               </svg>

                            </span>
                            </div><!-- /.iconbox-icon-wrap -->
                            <div class="contents">
                                <h3>Social Media Strategy and Campaigns</h3>
                            </div><!-- /.contants -->
                        </div><!-- /.iconbox -->
                    </div>
                    <div class="lqd-column col-md-4 col-sm-6 mb-40">

                        <div class="iconbox iconbox-xl iconbox-icon-image iconbox-shadow border-radius-5 px-5 pt-40 ">
                            <div class="iconbox-icon-wrap">
                            <span class="iconbox-icon-container text-black">
                                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                        <rect x="0" y="0" width="24" height="24"/>
                                        <rect fill="#4d148c" x="2" y="6" width="13" height="12" rx="2"/>
                                        <path d="M22,8.4142119 L22,15.5857848 C22,16.1380695 21.5522847,16.5857848 21,16.5857848 C20.7347833,16.5857848 20.4804293,16.4804278 20.2928929,16.2928912 L16.7071064,12.7071013 C16.3165823,12.3165768 16.3165826,11.6834118 16.7071071,11.2928877 L20.2928936,7.70710477 C20.683418,7.31658067 21.316583,7.31658098 21.7071071,7.70710546 C21.8946433,7.89464181 22,8.14899558 22,8.4142119 Z" fill="#4d148c" opacity="0.3"/>
                                    </g>
                                </svg>
                            </span>
                            </div><!-- /.iconbox-icon-wrap -->
                            <div class="contents">
                                <h3>Virtual Live Streaming</h3>

                            </div><!-- /.contants -->
                        </div><!-- /.iconbox -->

                    </div>
                    <div class="lqd-column col-md-4 col-sm-6 mb-40">

                        <div class="iconbox iconbox-xl iconbox-icon-image iconbox-shadow border-radius-5 px-5 pt-40 ">
                            <div class="iconbox-icon-wrap">
                            <span class="iconbox-icon-container text-black">
                                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                        <polygon points="0 0 24 0 24 24 0 24"/>
                                        <path d="M5.74714567,13.0425758 C4.09410362,11.9740356 3,10.1147886 3,8 C3,4.6862915 5.6862915,2 9,2 C11.7957591,2 14.1449096,3.91215918 14.8109738,6.5 L17.25,6.5 C19.3210678,6.5 21,8.17893219 21,10.25 C21,12.3210678 19.3210678,14 17.25,14 L8.25,14 C7.28817895,14 6.41093178,13.6378962 5.74714567,13.0425758 Z" fill="#4d148c" opacity="0.3"/>
                                        <path d="M11.1288761,15.7336977 L11.1288761,17.6901712 L9.12120481,17.6901712 C8.84506244,17.6901712 8.62120481,17.9140288 8.62120481,18.1901712 L8.62120481,19.2134699 C8.62120481,19.4896123 8.84506244,19.7134699 9.12120481,19.7134699 L11.1288761,19.7134699 L11.1288761,21.6699434 C11.1288761,21.9460858 11.3527337,22.1699434 11.6288761,22.1699434 C11.7471877,22.1699434 11.8616664,22.1279896 11.951961,22.0515402 L15.4576222,19.0834174 C15.6683723,18.9049825 15.6945689,18.5894857 15.5161341,18.3787356 C15.4982803,18.3576485 15.4787093,18.3380775 15.4576222,18.3202237 L11.951961,15.3521009 C11.7412109,15.173666 11.4257142,15.1998627 11.2472793,15.4106128 C11.1708299,15.5009075 11.1288761,15.6153861 11.1288761,15.7336977 Z" fill="#4d148c" fill-rule="nonzero" transform="translate(11.959697, 18.661508) rotate(-90.000000) translate(-11.959697, -18.661508) "/>
                                    </g>
                                </svg>
                            </span>
                            </div><!-- /.iconbox-icon-wrap -->
                            <div class="contents">
                                <h3>Web Hosting</h3>

                            </div><!-- /.contants -->
                        </div><!-- /.iconbox -->

                    </div>
                    <div class="lqd-column col-md-4 col-sm-6 mb-40">

                        <div class="iconbox iconbox-xl iconbox-icon-image iconbox-shadow border-radius-5 px-5 pt-40 ">
                            <div class="iconbox-icon-wrap">
                            <span class="iconbox-icon-container text-black">
                                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                        <rect x="0" y="0" width="24" height="24"/>
                                        <path d="M8,3 L8,3.5 C8,4.32842712 8.67157288,5 9.5,5 L14.5,5 C15.3284271,5 16,4.32842712 16,3.5 L16,3 L18,3 C19.1045695,3 20,3.8954305 20,5 L20,21 C20,22.1045695 19.1045695,23 18,23 L6,23 C4.8954305,23 4,22.1045695 4,21 L4,5 C4,3.8954305 4.8954305,3 6,3 L8,3 Z" fill="#4d148c" opacity="0.3"/>
                                        <path d="M11,2 C11,1.44771525 11.4477153,1 12,1 C12.5522847,1 13,1.44771525 13,2 L14.5,2 C14.7761424,2 15,2.22385763 15,2.5 L15,3.5 C15,3.77614237 14.7761424,4 14.5,4 L9.5,4 C9.22385763,4 9,3.77614237 9,3.5 L9,2.5 C9,2.22385763 9.22385763,2 9.5,2 L11,2 Z" fill="#4d148c"/>
                                        <rect fill="#4d148c" opacity="0.3" x="10" y="9" width="7" height="2" rx="1"/>
                                        <rect fill="#4d148c" opacity="0.3" x="7" y="9" width="2" height="2" rx="1"/>
                                        <rect fill="#4d148c" opacity="0.3" x="7" y="13" width="2" height="2" rx="1"/>
                                        <rect fill="#4d148c" opacity="0.3" x="10" y="13" width="7" height="2" rx="1"/>
                                        <rect fill="#4d148c" opacity="0.3" x="7" y="17" width="2" height="2" rx="1"/>
                                        <rect fill="#4d148c" opacity="0.3" x="10" y="17" width="7" height="2" rx="1"/>
                                    </g>
                                </svg>
                            </span>
                            </div><!-- /.iconbox-icon-wrap -->
                            <div class="contents">
                                <h3>Event Planning and Management</h3>

                            </div><!-- /.contants -->
                        </div><!-- /.iconbox -->

                    </div>
                </div><!-- /.row -->
            </div><!-- /.container -->
        </section>

        <section class="vc_row pt-50 pb-200">
            <div class="container">
                <div class="row">

                    <div class="lqd-column col-md-10 col-md-offset-1 text-center" data-custom-animations="true"
                         data-ca-options='{"triggerHandler":"inview","animationTarget":"all-childs","duration":1200,"delay":100,"initValues":{"translateY":80,"opacity":0},"animations":{"translateY":0,"opacity":1}}'>

                        <header class="fancy-title fancy-title-big mb-6">
                            <h2 data-split-text="true" data-split-options='{"type":"lines"}'>
                                Let's create something
                                <span class="font-size-2-15x lh-15 text-purple" data-fittext="true" data-fittext-options='{ "maxFontSize": 70, "minFontSize": 40, "compressor": 0.5 }'>extra-ordinary</span>
                                for you too?
                            </h2>
                        </header><!-- /.fancy-title -->

                        <a href="/contact-us"
                           class="btn btn-purple text-uppercase circle btn-bordered border-thin font-size-14 font-weight-semibold"
                           data-localscroll="true" data-localscroll-options='{"scrollBelowSection":true}'>
                            <span>
                              <span class="btn-txt">Start a project</span>
                            </span>
                        </a>

                    </div><!-- /.col-md-10 col-md-offset-1 -->

                </div><!-- /.row -->
            </div><!-- /.container -->
        </section>
    </main>
</template>

<script>
    import { DynamicAssets } from "../../utils/DynamicAssets";

    export default {
        name: 'Home',
        mounted() {

            DynamicAssets.removeScriptsPage();
            DynamicAssets.loadScriptPage()
        }
    }
</script>
