export const DynamicAssets = {
    srcs: [
        '/assets/js/theme.js'
    ],
    loadedScripts: false,
    loadScriptPage () {
        let isFound = false;
        // const scripts = document.getElementsByTagName('script')
        // for (let i = 0; i < scripts.length; ++i) {
        //   if (scripts[i].getAttribute('src') != null && scripts[i].getAttribute('src').includes('loader')
        //   ) {
        //     isFound = false
        //   } else {
        //     isFound = false
        //   }
        // }
        if (!isFound) {
            for (let i = 0; i < this.srcs.length; i++) {
                const node = document.createElement('script');
                node.src = this.srcs[i];
                node.type = 'text/javascript';
                node.async = false;
                node.charset = 'utf-8';
                document.getElementById('scripts_page').appendChild(node)
            }
        }
    },

    removeScriptsPage () {
        // eslint-disable-next-line no-console
        document.getElementById('scripts_page').innerHTML = ''
    },
    loadCSSs () {
        for (let i = 0; i < this.styles.length; i++) {
            this.loadCSS(this.styles[i])
        }
    },

    loadCSS (url) {
        const link = document.createElement('link');
        link.href = url;
        link.rel = 'stylesheet';
        link.type = 'text/css';

        const head = document.getElementsByTagName('head')[0];
        const style = head.getElementsByTagName('style')[0];

        head.insertBefore(link, style)
    }
};
